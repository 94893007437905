import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import MainPage from './MainPage';
import AboutUs from './AboutUs';
import Services from './Services';
import Works from './Works';
import WorksDetail from './WorksDetail';
import Blog from './Blog';
import BlogDetail from './BlogDetail';
import Contact from './Contact';
import LoadingScreen from './LoadingScreen';
import ScrollToTop from './ScrolToTop';
import mainPageIcon from "./Assets/mainPageIcon.png";
function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 4000); // Minimum loading time of 4 seconds

    return () => clearTimeout(timer);
  }, []);
  <svg width="31" height="46" viewBox="0 0 31 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_1211)">
      <path d="M26.6074 20.724C26.6074 20.724 26.1409 19.9651 25.3628 19.8489C24.4458 19.7137 15.5105 20.0748 15.5105 20.0748C15.5105 20.0748 22.3553 24.2005 22.7556 33.6912C22.7556 33.6912 23.6193 23.6022 31.0016 22.7033C31.0016 22.7033 30.3962 20.1448 26.609 20.7224L26.6074 20.724Z" fill="#EC1C24" />
      <path d="M10.7969 0.5C10.7969 0.5 0.0276481 7.41157 5.00786 16.5268C9.16961 24.1432 14.0497 24.603 17.0685 31.8073C17.6658 33.2281 18.1905 34.9067 18.6199 36.9576C18.5731 36.8176 17.5528 33.8868 15.3945 30.6777C14.2128 28.918 12.6905 27.0771 10.7985 25.5688C5.36463 21.2379 0.792841 17.7853 1.15284 11.2683C1.15284 11.2683 -3.33985 18.4949 5.41467 27.6181C5.41467 27.6181 6.06525 28.1733 7.09519 29.1232C7.55043 29.5448 8.08639 30.046 8.66755 30.6077C8.66755 30.6077 15.9998 38.3227 16.3276 39.2169C16.3615 39.3092 16.3954 39.3521 16.4309 39.3601C17.9144 41.4173 19.1526 43.5271 19.8 45.4905C19.8 45.4936 19.8032 45.4952 19.8032 45.4984C19.8032 45.4984 24.7511 34.5264 18.1986 26.2163C11.6428 17.9094 5.12893 13.1855 10.7969 0.5Z" fill="#EC1C24" />
    </g>
    <defs>
      <clipPath id="clip0_1_1211">
        <rect width="31" height="45" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>

  return (
    <Router>
      <div className="bg-black h-screen flex items-center justify-center">
        <div className='w-full items-center flex flex-col justify-center'>
          <div className='w-[62px] h-[90px]'>
            <svg
              width="31"
              height="46"
              viewBox="0 0 31 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className='w-full h-full'
            >
              <g clipPath="url(#clip0_1_1211)">
                <path
                  d="M26.6074 20.724C26.6074 20.724 26.1409 19.9651 25.3628 19.8489C24.4458 19.7137 15.5105 20.0748 15.5105 20.0748C15.5105 20.0748 22.3553 24.2005 22.7556 33.6912C22.7556 33.6912 23.6193 23.6022 31.0016 22.7033C31.0016 22.7033 30.3962 20.1448 26.609 20.7224L26.6074 20.724Z"
                  fill="#EC1C24"
                />
                <path
                  d="M10.7969 0.5C10.7969 0.5 0.0276481 7.41157 5.00786 16.5268C9.16961 24.1432 14.0497 24.603 17.0685 31.8073C17.6658 33.2281 18.1905 34.9067 18.6199 36.9576C18.5731 36.8176 17.5528 33.8868 15.3945 30.6777C14.2128 28.918 12.6905 27.0771 10.7985 25.5688C5.36463 21.2379 0.792841 17.7853 1.15284 11.2683C1.15284 11.2683 -3.33985 18.4949 5.41467 27.6181C5.41467 27.6181 6.06525 28.1733 7.09519 29.1232C7.55043 29.5448 8.08639 30.046 8.66755 30.6077C8.66755 30.6077 15.9998 38.3227 16.3276 39.2169C16.3615 39.3092 16.3954 39.3521 16.4309 39.3601C17.9144 41.4173 19.1526 43.5271 19.8 45.4905C19.8 45.4936 19.8032 45.4952 19.8032 45.4984C19.8032 45.4984 24.7511 34.5264 18.1986 26.2163C11.6428 17.9094 5.12893 13.1855 10.7969 0.5Z"
                  fill="#EC1C24"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_1211">
                  <rect width="31" height="45" fill="white" transform="translate(0 0.5)" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <p className='text-white text-lg p-2 max-md:text-center max-md:w-5/6'>
            We are currently undergoing maintenance,
          </p>
          <p className='text-white text-lg p-2 max-md:text-center max-md:w-5/6'>
            but you can still enjoy browsing our catalog during this time!
          </p>
          <button
            onClick={() => window.open('/horus-katalog.pdf', '_blank')}
            className='bg-black border border-[#EC1C25] text-white py-2 px-4 rounded mt-4 hover:bg-[#EC1C25] transition'
          >
            Download Catalog
          </button>
        </div>
        {/* <Navbar /> */}
        {/* <ScrollToTop /> */}
        {/* <Routes>
          <Route path="/home" element={<MainPage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/works" element={<Works />} />
          <Route path="/works/:id" element={<WorksDetail />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-detail/:id" element={<BlogDetail />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/" element={<MainPage />} />
        </Routes> */}

      </div>
    </Router >
  );
}

export default App;
